// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/gamegend/GIF_GAMEGEND_3_Light.webp";
import bgBack from "assets/images/gamegend/GIF_GAMEGEND_2_Light.webp";
import { useSelector } from "react-redux";

function Information() {
  const link = useSelector((state) => state.referentiel.link);
  return link === null ? null : (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="published_with_changes"
                title={<>Envie de nous rejoindre ?</>}
              />
              <RotatingCardBack
                image={bgBack}
                title="Rendez-vous sur HelloAsso"
                action={{
                  type: "external",
                  route: `${link.find((l) => l.libelle === "HelloAsso").value}`,
                  label: "Clique ici",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="radio_button_checked"
                  title="Des événements gaming"
                  description="PGW, Geek Legend, Gamer Assembly etc... "
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="radio_button_checked"
                  title="Des tournois e-sport"
                  description="Des compétitions sur différents jeux avec des récompenses à la clé!"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="radio_button_checked"
                  title="Des soirées multi-gaming"
                  description="Des soirées où nous pouvons nous retrouver entre nous pour jouer à certains jeux."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="radio_button_checked"
                  title="Une communauté soudée"
                  description="Un discord avec pas moins de 1000 membres !"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
