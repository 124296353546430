// react-router-dom components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Data
import planningPgw from "../../../assets/images/pgw/Planning.webp";
import { useSelector } from "react-redux";
import MKButton from "../../../components/MKButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const TwitchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
    <path
      fill="#6441A5"
      d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z"
    />
  </svg>
);

function Pgw() {
  const link = useSelector((state) => state.referentiel.link);
  return link === null ? null : (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge variant="contained" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Paris Games Week 2024
          </MKTypography>
          <br />
          <MKTypography variant="body1" color="text">
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              sx={{
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <span>Planning du live caritatif</span>
              <MKButton
                component="a"
                href={`${link.find((e) => e.libelle === "linkRzoTwitch").value}`}
                target="_blank"
                rel="noreferrer"
                variant={"gradient"}
                color={"primary"}
                size="small"
              >
                <TwitchIcon />
                Lien du stream
                <br />
                Cliquer ici !
                <TwitchIcon />
              </MKButton>
            </Stack>
          </MKTypography>
        </Grid>
        <Grid container spacing={0} justifyContent="center">
          <MKBox component="img" src={planningPgw} alt="PGW" width="100%" opacity={1} />
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pgw;
