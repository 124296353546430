// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import data from "../../../Presentation/sections/data/pagesData";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  const renderData = data.map(({ name }) => (
    <Grid item xs={2} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}></Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            La commission Partenariat et Rayonnement
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            <br />
            Le pôle partenariat de l&apos;association Gamegend, vise à établir des relations
            mutuellement bénéfiques avec des partenaires externes afin de soutenir financièrement et
            promouvoir le développement de l&apos;association à travers des évènements et des
            équipes esport.
            <br />
            <br />
            Les partenaires pourront agir en tant que sponsors directs des différentes équipes
            esport afin de couvrir divers domaines tels que les déplacements, les maillots, les
            équipements, mais également les frais d&apos;inscriptions aux différents
            évènements/tournois.
            <br />
            <br />
            Nous souhaitons que les partenariats avec les entreprises et d&apos;autres associations
            soient alignés sur les valeurs de notre association et celles de la gendarmerie. Pour
            cela les membres de ce pôles participent au choix des différents acteurs avec lesquels
            nous allons collaborer. Ils seront également responsables d’établir les négociations et
            la rédaction des contrats afin d&apos;accroître la visibilité de l&apos;association et à
            son rayonnement en dehors de la gendarmerie.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
