import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCommissionValue,
  fetchEquipeValue,
  fetchEventValue,
  fetchReferentielValue,
  fetchPartenaireValue,
  fetchStaffValue,
} from "./store/slices/referentielSlice";
import DefaultNavbar from "./examples/Navbars/DefaultNavbar";
import footerRoutes from "./footer.routes";
import DefaultFooter from "./examples/Footers/DefaultFooter";

export default function App() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const link = useSelector((state) => state.referentiel.link);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Fetch initial data on app load
  useEffect(() => {
    const fetchReferentielData = async () => {
      try {
        // Dispatch the thunk to fetch data
        await dispatch(fetchReferentielValue()).unwrap();
      } catch (err) {
        console.error("Failed to fetch Referentiel data:", err);
      }
    };
    const fetchCommissionData = async () => {
      try {
        // Dispatch the thunk to fetch data
        await dispatch(fetchCommissionValue()).unwrap();
      } catch (err) {
        console.error("Failed to fetch Commission data:", err);
      }
    };
    const fetchEquipeData = async () => {
      try {
        // Dispatch the thunk to fetch data
        await dispatch(fetchEquipeValue()).unwrap();
      } catch (err) {
        console.error("Failed to fetch Equipe data:", err);
      }
    };
    const fetchStaffData = async () => {
      try {
        // Dispatch the thunk to fetch data
        await dispatch(fetchStaffValue()).unwrap();
      } catch (err) {
        console.error("Failed to fetch Staff data:", err);
      }
    };
    const fetchPartenaireData = async () => {
      try {
        // Dispatch the thunk to fetch data
        await dispatch(fetchPartenaireValue()).unwrap();
      } catch (err) {
        console.error("Failed to fetch Partenaire data:", err);
      }
    };
    const fetchEventData = async () => {
      try {
        // Dispatch the thunk to fetch data
        await dispatch(fetchEventValue()).unwrap();
      } catch (err) {
        console.error("Failed to fetch Event data:", err);
      }
    };
    fetchReferentielData();
    fetchCommissionData();
    fetchEquipeData();
    fetchStaffData();
    fetchPartenaireData();
    fetchEventData();
  }, [dispatch]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const header = () => {
    return link === null ? null : (
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: `${link.find((l) => l.libelle === "HelloAssoCotisation").value}`,
          label: "Devenir adhérent",
          color: "primary",
        }}
        sticky
      />
    );
  };

  const footer = () => {
    return <DefaultFooter content={footerRoutes} link={link} />;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {header()}
      <Routes>
        {getRoutes(routes)}
        <Route path="/presentation" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/presentation" />} />
      </Routes>
      {footer()}
    </ThemeProvider>
  );
}
