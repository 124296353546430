// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/gamegend/Logo_rondache_avec_petite_grenade_noir.png";

// Routes
import ContactUs from "./pages/ContactUs";
import TermsPage from "./layouts/pages/legal/terms";
import PoliciesPage from "./layouts/pages/legal/policies";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "GameGend",
    image: logoCT,
    route: "/",
  },
  menus: [
    {
      name: "support",
      items: [{ name: "Contact", route: "/pages/contact-us", component: <ContactUs /> }],
    },
    {
      name: "legal",
      items: [
        {
          name: "Conditions légales",
          route: "/pages/legal/terms",
          component: <TermsPage />,
        },
        {
          name: "Protection des données personnelles",
          route: "/pages/legal/policies",
          component: <PoliciesPage />,
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} .{" "}
      <MKTypography
        component="a"
        href="https://gamegend.fr"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        GameGend
      </MKTypography>
      .
    </MKTypography>
  ),
};
