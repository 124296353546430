// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

import { useSelector } from "react-redux";
function Counters() {
  const link = useSelector((state) => state.referentiel.link);
  return link === null ? null : (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              suffix="+"
              count={link.find((e) => e.libelle === "nbAdherents").value}
              title="Adhérents"
              // description="Sympathisants, "
            />
          </Grid>
          {/*<Grid item xs={12} md={4}>*/}
          {/*  <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />*/}
          {/*  <DefaultCounterCard*/}
          {/*    count={600}*/}
          {/*    suffix="+"*/}
          {/*    title="Membres Discord"*/}
          {/*    // description="Mix the sections, change the colors and unleash your creativity"*/}
          {/*  />*/}
          {/*  <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, ml: 0 }} />*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={6}>
            <DefaultCounterCard
              suffix="+"
              count={link.find((e) => e.libelle === "nbAll").value}
              title="Membres Discord"
              // description="Save 3-4 weeks of work when you use our pre-made pages for your website"
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
