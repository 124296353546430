// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import homme from "assets/images/gamegend/PHOTO_GAMEGEND_HOMME.png";
import { useSelector } from "react-redux";
import femme from "assets/images/gamegend/PHOTO_GAMEGEND_FEMME.png";
import FormatUser from "../../../../pipe/FormatUser";

function Team() {
  const staff = useSelector((state) => state.referentiel.staff);
  return staff === null ? null : (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              L&apos;équipe communication
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {staff
            .filter((p1) => p1.commissionId === 3)
            .map((p2) => (
              <Grid key={p2.id} item xs={12} lg={6}>
                <MKBox mb={1}>
                  <HorizontalTeamCard
                    image={p2.male ? homme : femme}
                    name={FormatUser(p2)}
                    position={{ color: "info", label: p2.info }}
                    description={""}
                  />
                </MKBox>
              </Grid>
            ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
