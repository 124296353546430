// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import homme from "assets/images/gamegend/PHOTO_GAMEGEND_HOMME.png";
import femme from "assets/images/gamegend/PHOTO_GAMEGEND_FEMME.png";
import { useSelector } from "react-redux";
import FormatUser from "../../../../pipe/FormatUser";

//import banniere from "assets/images/gamegend/X.png";

function Teams() {
  const staff = useSelector((state) => state.referentiel.staff);
  const equipe = useSelector((state) => state.referentiel.equipe);
  const printRole = (user, team) => {
    if (user.equipeId === 0) {
      return user.info;
    } else {
      return `Team ${team.libelle}`;
    }
  };

  return staff === null ? null : (
    <>
      {equipe.map((e1) => (
        <MKBox
          key={e1.id}
          component="section"
          variant="gradient"
          position="relative"
          bgColor="dark"
          py={6}
          px={{ xs: 2, lg: 0 }}
          mx={-2}
        >
          <Container>
            <Grid container>
              <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                <MKTypography variant="h3" color="white">
                  {e1.libelle}
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {staff
                .filter((p1) => p1.commissionId === 2 && p1.equipeId === e1.id)
                .map((p2) => (
                  <Grid key={p2.id} item xs={12} lg={6}>
                    <MKBox mb={1}>
                      <HorizontalTeamCard
                        image={p2.male ? homme : femme}
                        name={FormatUser(p2)}
                        position={{
                          color: "info",
                          label: printRole(p2, e1),
                        }}
                        description={""}
                      />
                    </MKBox>
                  </Grid>
                ))}
            </Grid>
          </Container>
        </MKBox>
      ))}
    </>
  );
}

export default Teams;
