import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import environment from "../../environments/environment";

// Création d'un thunk asynchrone
export const fetchReferentielValue = createAsyncThunk("getReferentiel", async () => {
  const response1 = await fetch(environment.API_BASE_URL + "/referentiel");
  const data1 = await response1.json();
  return data1;
});

export const fetchCommissionValue = createAsyncThunk("getCommission", async () => {
  const response2 = await fetch(environment.API_BASE_URL + "/commission");
  const data2 = await response2.json();
  return data2;
});

export const fetchEquipeValue = createAsyncThunk("getEquipe", async () => {
  const response3 = await fetch(environment.API_BASE_URL + "/equipe");
  const data3 = await response3.json();
  return data3;
});

export const fetchStaffValue = createAsyncThunk("getStaff", async () => {
  const response = await fetch(environment.API_BASE_URL + "/staff");
  const data4 = await response.json();
  return data4;
});

export const fetchPartenaireValue = createAsyncThunk("getPartenaire", async () => {
  const response = await fetch(environment.API_BASE_URL + "/partenaire");
  const data5 = await response.json();
  return data5;
});

export const fetchEventValue = createAsyncThunk("getEvent", async () => {
  const response = await fetch(environment.API_BASE_URL + "/event");
  const data6 = await response.json();
  return data6;
});

const referentielSlice = createSlice({
  name: "referentiel",
  initialState: {
    link: null,
    commission: null,
    equipe: null,
    staff: null,
    partenaire: null,
    event: null,
    loading: false,
    loaded: false,
  },
  reducers: {
    increment: (state) => {
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReferentielValue.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchReferentielValue.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.link = action.payload;
      })
      .addCase(fetchReferentielValue.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
      })
      .addCase(fetchCommissionValue.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchCommissionValue.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.commission = action.payload;
      })
      .addCase(fetchCommissionValue.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
      })
      .addCase(fetchEquipeValue.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchEquipeValue.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.equipe = action.payload;
      })
      .addCase(fetchEquipeValue.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
      })
      .addCase(fetchStaffValue.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchStaffValue.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.staff = action.payload;
      })
      .addCase(fetchStaffValue.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
      })
      .addCase(fetchPartenaireValue.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchPartenaireValue.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.partenaire = action.payload;
      })
      .addCase(fetchPartenaireValue.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
      })
      .addCase(fetchEventValue.pending, (state) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchEventValue.fulfilled, (state, action) => {
        state.loading = false;
        state.loaded = true;
        state.event = action.payload;
      })
      .addCase(fetchEventValue.rejected, (state) => {
        state.loading = false;
        state.loaded = false;
      });
  },
});

export const { increment, decrement, incrementByAmount } = referentielSlice.actions;

export default referentielSlice.reducer;
