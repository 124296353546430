// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import data from "../../../Presentation/sections/data/pagesData";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  const renderData = data.map(({ name }) => (
    <Grid item xs={2} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}></Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            La commission E-Sport
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            La commission esport de l&apos;association se distingue par une particularité unique :
            elle mobilise les talents internes issus de la gendarmerie pour atteindre ses objectifs
            ambitieux dans le domaine du sport électronique. Cette stratégie permet de tirer parti
            des compétences et de l&apos;expertise professionnelles déjà présentes au sein de
            l&apos;organisation, en recrutant, testant et développant les capacités esportives de
            membres.
            <br />
            <br />
            Les gestionnaires d&apos;équipe, choisis pour leur savoir-faire spécifique, sont au cœur
            de cette démarche, garantissant une approche sur mesure qui valorise les compétences
            individuelles tout en visant l&apos;excellence collective. Cette synergie entre
            compétences internes et les objectifs esportifs de la commission crée une dynamique
            puissante, permettant à l&apos;association de se positionner avantageusement dans
            l&apos;univers compétitif de l&apos;esport.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
