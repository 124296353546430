// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Images
import photo4 from "../../../assets/images/photo/PGW2023/33.png";
import photo7 from "../../../assets/images/photo/PGW2023/72.png";
import photo8 from "../../../assets/images/photo/PGW2023/IMG_2915 copie.jpg";
import photo9 from "../../../assets/images/photo/PGW2023/IMG_3376_3.jpg";
import photo10 from "../../../assets/images/photo/PGW2023/IMG_3418.jpg";
import photo11 from "../../../assets/images/photo/PGW2023/IMG_3638.jpg";
import photo12 from "../../../assets/images/photo/PGW2023/IMG_3646.jpg";
import photo13 from "../../../assets/images/photo/PGW2023/IMG_3656.jpg";
import photo14 from "../../../assets/images/photo/PGW2023/IMG_3717.jpg";
import GA2 from "../../../assets/images/photo/GA2024/IMG_4160.webp";
import GA3 from "../../../assets/images/photo/GA2024/IMG_4170.webp";
import GA4 from "../../../assets/images/photo/GA2024/IMG_4173.webp";
import GA5 from "../../../assets/images/photo/GA2024/IMG_4176.webp";
import GA6 from "../../../assets/images/photo/GA2024/IMG_4178.webp";
import GA7 from "../../../assets/images/photo/GA2024/IMG_4201.webp";
import { ImageList, ImageListItem } from "@mui/material";

const PGW_2023 = [photo4, photo7, photo14, photo8, photo9, photo10, photo11, photo12, photo13];
const GA_2024 = [GA2, GA3, GA4, GA5, GA6, GA7];

function Album() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Nos medias</MKTypography>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={10} justifyContent="center">
          <Grid item xs={12} sm={12}>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12} sm={12}>
                Paris Games Week 2023
              </Grid>
              <Grid item xs={12} sm={12}>
                <ImageList variant="masonry" cols={4} gap={8}>
                  {PGW_2023.map((item) => (
                    <ImageListItem key={item}>
                      <img
                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                        alt={item}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Grid container spacing={0} justifyContent="center">
              <Grid item xs={12} sm={12}>
                Gamers Assembly 2024
              </Grid>
              <Grid item xs={12} sm={12}>
                <ImageList variant="masonry" cols={3} gap={8}>
                  {GA_2024.map((item) => (
                    <ImageListItem key={item}>
                      <img
                        srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item}?w=164&h=164&fit=crop&auto=format`}
                        alt={item}
                        loading="lazy"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*<Grid container spacing={10} justifyContent="center">*/}
        {/*  <Grid item xs={6} md={4} lg={3}>*/}
        {/*    <MKBox component="img" src={photo1} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={3}>*/}
        {/*    <MKBox component="img" src={photo2} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo3} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo4} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo5} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo6} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo7} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo8} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo9} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo10} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo11} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo12} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo13} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*  <Grid item xs={6} md={4} lg={2}>*/}
        {/*    <MKBox component="img" src={photo14} alt="photo" width="100%" opacity={1} />*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Container>
    </MKBox>
  );
}

export default Album;
