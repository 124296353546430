// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
//import GitHubIcon from "@mui/icons-material/GitHub";

// Pages
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Media from "./pages/Media";
import Communication from "./pages/Commission/Communication";
import Esport from "./pages/Commission/Esport";
import Partenariat from "./pages/Commission/Partenariat";
import PoliciesPage from "./layouts/pages/legal/policies";
import TermsPage from "./layouts/pages/legal/terms";

const routes = [
  {
    name: "Association",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Notre histoire",
        route: "/pages/about-us",
        component: <AboutUs />,
      },
      {
        name: "Medias",
        route: "/pages/media",
        component: <Media />,
      },
      // {
      //   name: "Contact",
      //   route: "/pages/landing-pages/author",
      //   component: <Author />,
      // },
    ],
  },
  {
    name: "Commissions",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "E-Sport",
        description: "Voir les informations",
        route: "/pages/commission/esport",
        component: <Esport />,
      },
      {
        name: "Partenariat",
        description: "Voir les informations",
        route: "/pages/commission/partenariat",
        component: <Partenariat />,
      },
      {
        name: "Communication",
        description: "Voir les informations",
        route: "/pages/commission/communication",
        component: <Communication />,
      },
    ],
  },
  {
    name: "Contact",
    icon: <Icon>email</Icon>,
    route: "/pages/contact-us",
    component: <ContactUs />,
  },
  {
    name: "",
    icon: <Icon>info</Icon>,
    collapse: [
      {
        name: "Conditions légales",
        route: "/pages/legal/terms",
        component: <TermsPage />,
      },
      {
        name: "Protection des données personnelles",
        route: "/pages/legal/policies",
        component: <PoliciesPage />,
      },
      // {
      //   name: "Contact",
      //   route: "/pages/landing-pages/author",
      //   component: <Author />,
      // },
    ],
  },
];

export default routes;
