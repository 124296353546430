// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
});

function Information() {
  const partenaire = useSelector((state) => state.referentiel.partenaire);
  return partenaire === null ? null : (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Nos partenaires</MKTypography>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={4} justifyContent="center" alignItems={"center"}>
          {partenaire.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <Card
                  key={item.id}
                  sx={{
                    height: "150px",
                    padding: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.2)",
                      outline: "outset #DB2364",
                    },
                  }}
                >
                  <StyledImage
                    src={`data:image/webp;base64,${item.image}`}
                    alt={item.libelle}
                    loading="lazy"
                  />
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
