import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counterSlice";
import referentielSlice from "./slices/referentielSlice";
import environment from "../environments/environment";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    referentiel: referentielSlice,
  },
  devTools: !environment.PRODUCTION,
});
