// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h1" fontWeight="bold">
            Protection des données personnelles
          </MKTypography>
          <br />
          <br />
          <MKTypography variant="h4" fontWeight="bold">
            Responsable de traitement et coordonnées.
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Responsable de traitement et coordonnées.
            </MKTypography>
            <br />
            <br />
            Le responsable de traitement est l&apos;association GAMEGEND.
            <br />
            <br />
            GAMEGEND est une association loi 1901 déclarée auprès de la Préfecture des hauts de
            seine (RNA N° W923011444, SIRET N° 923 686 174 00015) sis au 5 Allée Forestière, 92 410
            Ville d&apos;Avray.
            <br />
            <br />
            L&apos;adresse courriel de contact est gamegend@gendarmerie.interieur.gouv.fr
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Données collectées
            </MKTypography>
            <br />
            <br />
            Nous sommes susceptibles de collecter des données personnelles vous concernant dans les
            cas suivants: lors de votre navigation sur le site internet.
            <br />
            Nous utilisons MATOMO ANALYTICS (recommandé par la CNIL) sur notre site pour mesurer
            l&apos;audience des personnes y naviguant.
            <br />
            Aucune adresse IP n’est collectée car l&apos;accès au site se fait via un proxy.
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Base légale du traitement
            </MKTypography>
            <br />
            <br />
            Les cookies utilisés le sont dans l&apos;intérêt légitime du responsable de traitement
            d’assurer le bon fonctionnement du site.
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Destinataire des données
            </MKTypography>
            <br />
            <br />
            Aucune personne ou société n&apos;est destinataire de ces données.
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Durée de conservation
            </MKTypography>
            <br />
            <br />
            Les données de mesures d&apos;audience sont conservées 1 an.
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Droits des personnes
            </MKTypography>
            <br />
            <br />
            Vous disposez d’un droit d&apos;accès aux données personnelles vous concernant, ainsi
            qu&apos;un rectification, modification et effacement. Vous pouvez également demander la
            limitation du traitement (dans les cas prévus par la loi).
            <br />
            Si vous estimez, après nous avoir contacté, que vos droits ne sont pas respectés vous
            pouvez contacter la CNIL.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}></Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
