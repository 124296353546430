// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import data from "../../Presentation/sections/data/pagesData";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../components/MKTypography";

function Information() {
  const renderData = data.map(({ name }) => (
    <Grid item xs={2} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}></Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Notre histoire
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            <br />
            Il était une fois, une poignée de camarades , se croisant au gré de réunions, partageant
            bien plus qu&apos;un uniforme : une passion pour le jeu vidéo.De fil en aiguille, au
            détour des conversations, l&apos;idée germe : pourquoi ne pas unir nos forces pour
            célébrer cette passion commune, bien loin des réunions interminables ?
            <br />
            <br />
            Ainsi naquit, courant 2023, GameGend, une association affiliée à la Gendarmerie
            Nationale et rassemblant les adeptes du joystick et des pixels. Notre objectif était
            clair : offrir à nos membres un espace où la passion pour le jeu vidéo pourrait
            s&apos;épanouir pleinement. Mais notre ambition ne s&apos;arrêtait pas là.
            <br />
            <br />
            Conscients que la gamification est bien plus qu&apos;un simple loisir, qu&apos;elle peut
            s&apos;immiscer dans tous les aspects de notre quotidien, notamment dans la formation et
            la prévention des infractions, nous avons décidé d&apos;aller au-delà du simple
            divertissement. Nous avons voulu assumer et valoriser cette dimension serious game en
            faisant de notre outil de jeu, un outil de cohésion et de développement collectif.
            <br />
            <br />
            Notre lieu de ralliement, notre QG virtuel, est notre Discord, vibrant de discussions
            enflammées et de parties endiablées. Mais comme tout espace de rencontre, il comporte
            des frais.
            <br />
            C&apos;est pourquoi nous avons décidé d&apos;officialiser notre association, non
            seulement pour offrir des services à nos membres et organiser des évènements, mais aussi
            pour assurer la pérennité de notre communauté en garantissant les ressources nécessaires
            à son fonctionnement.
            <br />
            <br />
            Ainsi, au-delà du plaisir de jouer ensemble, nous avons souhaité renforcer notre
            identité à travers des goodies et des symboles qui nous rassemblent. Car au-delà des
            pixels et des écrans, c&apos;est un véritable esprit de camaraderie et de solidarité qui
            anime GameGend, une communauté unie par la passion du jeu et le sens du devoir.
            <br />
            Notre histoire ne fait que commencer, mais déjà, elle est riche de rencontres, de défis
            et de succès. Et nous sommes fiers de pouvoir écrire ensemble les prochains chapitres de
            cette aventure, au service de notre passion et de notre engagement au sein de la
            Gendarmerie Nationale.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
