// react-router-dom components

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components

// Data
import { useSelector } from "react-redux";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
});

function Pages() {
  const event = useSelector((state) => state.referentiel.event);
  return event === null ? null : (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKBadge variant="contained" container sx={{ mb: 2 }} />
          <MKTypography variant="h2" fontWeight="bold">
            Nos actions
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            Retrouvez nous sur ces conventions
            <br />
          </MKTypography>
        </Grid>
        <Grid container spacing={4} justifyContent="center" alignItems={"center"}>
          {event.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <Card
                  key={item.id}
                  sx={{
                    height: "auto",
                    padding: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.2)",
                      outline: "outset #DB2364",
                    },
                  }}
                >
                  <StyledImage
                    src={`data:image/webp;base64,${item.image}`}
                    alt={item.libelle}
                    loading="lazy"
                  />
                </Card>
              </a>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pages;
