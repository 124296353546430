// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page sections
import Counters from "pages/Presentation/sections/Counters";
import Information from "pages/Presentation/sections/Information";
import Pages from "pages/Presentation/sections/Pages";
import Testimonials from "pages/Presentation/sections/Testimonials";

// Images
import bgImage2 from "assets/images/gamegend/Banniere_5_Light.webp";
import Pgw from "./sections/Pgw";
import { useSelector } from "react-redux";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const TiktokIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
    <path
      fill="currentColor"
      d="M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48"
    />
  </svg>
);

const TwitchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
    <path
      fill="currentColor"
      d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z"
    />
  </svg>
);

// Style de rotation pour les icônes
const rotateIconStyle = {
  display: "inline-block",
  transition: "transform 0.5s ease",
  "&:hover": {
    transform: "rotate(180deg)",
  },
};

function Presentation() {
  const link = useSelector((state) => state.referentiel.link);

  const socials = link
    ? [
        {
          icon: <FacebookIcon />,
          link: link.find((l) => l.libelle === "linkRzoFacebook").value,
        },
        {
          icon: <LinkedInIcon />,
          link: link.find((l) => l.libelle === "linkRzoLinkedIn").value,
        },
        {
          icon: <InstagramIcon />,
          link: link.find((l) => l.libelle === "linkRzoInstagram").value,
        },
        {
          icon: <TiktokIcon />,
          link: link.find((l) => l.libelle === "linkRzoTiktok").value,
        },
        {
          icon: <TwitchIcon />,
          link: link.find((l) => l.libelle === "linkRzoTwitch").value,
        },
      ]
    : [];

  return link === null ? null : (
    <>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {" "}
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            ></MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        {link.find((e) => e.libelle === "hidePgw").value.toLowerCase() !== "false" ? null : <Pgw />}
        <Information />
        <Pages />
        <Container sx={{ mt: 6 }}></Container>
        <Testimonials />
        <MKBox pt={18} pb={6}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Merci pour votre soutien!
                </MKTypography>
                <MKTypography variant="body1" color="text">
                  Pour nous suivre sur nos réseaux
                </MKTypography>
              </Grid>
              <Grid
                item
                xs={12}
                lg={7}
                my={{ xs: 5, lg: "auto" }}
                mr={{ xs: 0, lg: "auto" }}
                sx={{ textAlign: { xs: "center", lg: "right" } }}
              >
                {socials.map(({ icon, link }, key) => (
                  <MKTypography
                    key={link}
                    component="a"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    variant="h1"
                    color="dark"
                    opacity={0.8}
                    mr={key === socials.length - 1 ? 0 : 2}
                    sx={rotateIconStyle} // Appliquer la rotation avec 'sx'
                  >
                    {icon}
                  </MKTypography>
                ))}
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
    </>
  );
}

export default Presentation;
