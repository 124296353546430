// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import data from "../../../Presentation/sections/data/pagesData";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  const renderData = data.map(({ name }) => (
    <Grid item xs={2} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}></Grid>
  ));

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            La commission Communication
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            La commission communication joue un rôle crucial au sein de l&apos;association GameGend,
            qui représente et rassemble les passionnés de jeux vidéo au sein de la gendarmerie
            nationale. Cette commission est chargée de promouvoir les activités de
            l&apos;association, de renforcer sa visibilité et d&apos;assurer une communication
            efficace tant en interne qu&apos;en externe.
            <br />
            <br />
            Tout d&apos;abord, la commission communication est responsable de la gestion des
            différents canaux de communication de l&apos;association. Cela inclut la gestion des
            réseaux sociaux, du site web, des newsletters et de toute autre plateforme de
            communication utilisée pour diffuser des informations auprès des membres de
            l&apos;association et du grand public. Elle veille à ce que les informations relayées
            soient pertinentes, claires et cohérentes avec les objectifs et les valeurs de GameGend.
            <br />
            <br />
            En outre, la commission communication est chargée de promouvoir les événements et les
            activités organisés par l&apos;association. Elle conçoit et déploie des stratégies de
            communication pour attirer un public plus large, que ce soit pour des tournois de jeux
            vidéo, des conférences, des formations ou des actions caritatives. Elle s&apos;efforce
            également de maintenir un dialogue constant avec les membres de l&apos;association pour
            pour recueillir leurs besoins et leurs suggestions, afin d&apos;adapter les
            communications en conséquence. Un autre aspect important du rôle de la commission
            communication est la gestion de l&apos;image de l&apos;association. Elle veille à ce que
            l&apos;image de GameGend soit positive et professionnelle, en créant du contenu de et en
            et en surveillant l&apos;e-réputation de l&apos;association. Elle est également
            responsable de la réponse aux éventuelles crises de communication, en fournissant des
            informations transparentes et en gérant les relations avec les médias.
            <br />
            <br />
            Enfin, la commission communication collabore étroitement avec les autres commissions et
            les membres du bureau de l&apos;association pour assurer une communication transversale
            et cohérente. Elle participe à la définition des stratégies de développement de
            l&apos;association et contribue à renforcer le sentiment d&apos;appartenance des membres
            en favorisant l&apos;échange et le partage d&apos;informations.
            <br />
            <br />
            En somme, la commission communication est un pilier essentiel de l&apos;association
            GameGend, jouant un rôle central dans sa promotion, sa gestion de l&apos;image et son
            développement. Grâce à son travail, l&apos;association peut atteindre ses objectifs et
            offrir à ses membres une expérience enrichissante et valorisante dans le monde des jeux
            vidéo au sein de la gendarmerie nationale.
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}>
              {renderData}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
