// @mui material components
import Container from "@mui/material/Container";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import Grid from "@mui/material/Grid";
import MKTypography from "../../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={2}
          lg={30}
          flexDirection="column"
          sx={{ textAlign: "left", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h1" fontWeight="bold">
            Conditions légales
          </MKTypography>
          <br />
          <br />
          <MKTypography variant="h4" fontWeight="bold">
            Mentions légales
          </MKTypography>
          <MKTypography variant="body1" color="text">
            <br />
            Ce site est développé par et pour l&apos;association GAMEGEND.
            <br />
            <br />
            GAMEGEND est une association loi 1901 déclarée auprès de la Préfecture des hauts de
            seine (RNA N° W923011444, SIRET N° 923 686 174 00015) sis au 5 Allée Forestière, 92 410
            Ville d&apos;Avray.
            <br />
            <br />
            Son adresse de contact : gamegend@gendarmerie.interieur.gouv.fr
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Directeur de publication
            </MKTypography>
            <br />
            <br />
            Le responsable de publication est l&apos;association GAMEGEND.
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Propriété intellectuelle
            </MKTypography>
            <br />
            <br />
            Hors images, illustrations, photographies libre de droits utilisées, ou appartenant à
            des personnes physiques spécifiques créditées, les contenus appartiennent à GAMEGEND.
            <br />
            <br />
            <MKTypography variant="h8" fontWeight="bold">
              Hébergement
            </MKTypography>
            <br />
            <br />
            Le site est hébergé par CONTABO (société Allemande). Aschauer Straße 32a 81549 Munich
            Germany
            <br />
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: { xs: 8, lg: 16 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>
            <Grid container spacing={3}></Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
